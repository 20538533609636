.financial {
  .values {
    .main {
      background-color: $primary-color;

      span {
        color: #fff !important;
      }
    }

    .card {
      border-radius: 5px;
      padding: 25px;

      display: flex;
      flex-direction: column;

      position: relative;

      span {
        color: $primary-color;
      }

      .icon {
        background: aliceblue;
        border-radius: 50%;
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .name {
        margin-top: 15px;

        span {
          font-size: 20px;
        }
      }

      .value {
        span {
          font-size: 20px;
          font-weight: bolder;
          letter-spacing: 1.5px;
        }
      }
    }
  }

  .summary {
    .card {
      border-radius: 5px;
      padding: 25px 0;

      display: flex;
      justify-content: center;
      flex-direction: column;

      strong {
        font-size: 16px;
        color: $primary-color;
        padding-left: 25px;
      }
    }
  }

  .actions {
    // background: crimson;

    .card {
      border-radius: 5px;
      padding: 25px;

      // &:nth-child(2) {
      //   background: crimson;
      //   filter: blur(1px);
      // }

      .bank-accounts {
        strong {
          font-size: 16px;
          color: $primary-color;
        }

        .empty {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 10px 0;
        }

        .account {
          display: flex;
          align-items: center;
          gap: 0 10px;
          padding: 15px 0;

          .icon {
            width: 50px;
            height: 50px;
            background: cadetblue;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
          }

          .description {
            display: flex;
            flex-direction: column;
          }

          strong {
            font-size: 14px;
          }
        }

        .new-account {
          display: flex;
          justify-content: center;
          width: 100%;
          padding: 15px 0 0;
        }
      }

      .withdraw {
        strong {
          font-size: 16px;
          color: $primary-color;
        }

        .process {
          display: flex;
          flex-direction: column;
          gap: 15px 0;

          .disclaimer {
            display: flex;
            flex-direction: column;
            align-items: center;
          }
        }

        .do-withdraw {
          display: flex;
          justify-content: center;
          width: 100%;
          padding: 15px 0 0;
        }
      }

      .deposit {
        strong {
          font-size: 16px;
          color: $primary-color;
        }

        .process {
          display: flex;
          flex-direction: column;
          gap: 15px 0;
        }

        .terms {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .do-deposit {
          display: flex;
          justify-content: center;
          width: 100%;
          padding: 15px 0 0;
        }
      }
    }
  }

  .movements {
    .card {
      border-radius: 5px;
      padding: 25px;

      display: flex;
      flex-direction: column;
      gap: 20px 0;

      strong {
        font-size: 16px;
        color: $primary-color;
      }

      .list {
        margin-top: 20px;

        .content {
          display: flex;
          width: 100%;

          .name {
            width: 50%;
            display: flex;
            justify-content: flex-start;
          }

          .amount {
            width: 50%;
            display: flex;
            justify-content: flex-end;
          }
        }
      }
    }
  }

  .history {
    .card {
      border-radius: 5px;
      padding: 25px;

      display: flex;
      flex-direction: column;
      gap: 20px 0;

      strong {
        font-size: 16px;
        color: $primary-color;
      }

      .full-history {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 10px;

        span {
          cursor: pointer;
        }
      }
    }

    th {
      font-weight: bold;
    }
  }
}
