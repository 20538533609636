$sidebar-bg-color: $white;
$sidebar-color: $primary-color;
$icon-bg-color: $primary-color;
$highlight-color: $secondary-color;
$submenu-bg-color: #f5f6fa;

@import "~react-pro-sidebar/dist/scss/styles.scss";

.new-sidebar {
  min-height: 100%;
  position: absolute;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

  .pro-sidebar-content {
    font-weight: bold;
  }

  .pro-sidebar-header {
    height: 66px !important;
  }

  .pro-icon {
    color: $white !important;
  }

  .popper-inner {
    display: none;
  }
}
