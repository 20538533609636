.new-layout {
  margin-left: 100px;
  margin-top: 20px;
  padding: 0 30px 0 0;

  @media (max-width: 578px) {
    margin-left: 0;
    padding: 0 30px;
  }

  &.checkout {
    margin-left: 30px;
    @media(max-width: 578px) {
      margin-left: -12px;
    }
  }
}
