@import "~bootstrap-scss/bootstrap.scss";
@import "color";
@import "vendors/chartist/chartist";
@import "vendors/themify.scss";
@import "vendors/whether-icon.scss";
@import "vendors/icofont.scss";
@import "vendors/flag-icon.scss";
@import "vendors/icoicon/icons";
@import "vendors/fontawesome.scss";
@import "vendors/animate/animate";
@import "vendors/sticky/sticky";
@import "vendors/todo/todo";

@import "components/table-actions";

html,
body {
  min-height: 100%;
  position: relative;
  background: #f5f6fa;

  .card {
    border-radius: 5px !important;
  }
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: #a9d256 #cccccc;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 8px;
}

*::-webkit-scrollbar-track {
  background: #cccccc;
}

*::-webkit-scrollbar-thumb {
  background-color: #a9d256;
  border-radius: 15px;
  border: 3px none #ffffff;
}
