$rccs-mastercard-background: linear-gradient(25deg, #e67e22, #d35400) !important;
.rccs {
  &__card {
    &--front,
    &--back {
      border-radius: 5px !important;
    }
    &--mastercard {
      > div {
        color: $white !important;
      }

      .rccs__card__background {
        background: $rccs-mastercard-background;
      }
    }
  }
}
.new-checkout-payment-method {
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .payment-method-items {
    display: flex;
    gap: 0 1.5rem;
    padding: 1rem 0 3rem 0;

    @media (max-width: 578px) {
      padding: 2rem 0;
    }

    .method-button {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0 0.5rem;

      font-size: 1rem;
      width: 200px;
      height: 80px;
      border: 1px #bdc3c7 solid;
      border-radius: 3px;
      transition: 0.3s;
      color: $primary-color;

      span {
        margin-top: 2px;
        @media (max-width: 578px) {
          text-align: center;
        }
      }

      &.selected {
        transition: 0.3s;
        background: $primary-color;
        color: $white;
        border: 1px $primary-color solid;
      }

      &:first-child {
        @media (max-width: 578px) {
          gap: 0;

          span {
            margin-top: 8px;
          }
        }

        span {
          line-height: 15px;
        }
      }

      @media (max-width: 578px) {
        flex-direction: column;
        gap: 0.5rem 0rem;
      }
    }
  }

  .credit-card {
    animation: fadeIn 1s;
    display: flex;
    gap: 0 1rem;
    width: 100%;

    @media (max-width: 578px) {
      flex-direction: column-reverse;
      animation: fadeIn 0.1ms;
    }

    small {
      color: red;
    }

    .card-info {
      display: flex;
      flex-direction: column;
      width: 60%;
      gap: 1rem 0;
      font-weight: bold;

      @media (max-width: 578px) {
        width: 100%;
      }

      .card-info-extras {
        display: flex;
        gap: 0 2rem;
        width: 100%;

        @media (max-width: 578px) {
          flex-direction: column;
          gap: 1rem 0;
        }

        .number {
          width: 60%;
          @media (max-width: 578px) {
            width: 100%;
          }
        }

        .expiry {
          @media (max-width: 578px) {
            width: 100%;
          }
        }

        .cvv {
          width: 20%;
          @media (max-width: 578px) {
            flex-direction: column;
            width: 40%;
          }
        }
      }

      @media (max-width: 578px) {
        width: 100%;
      }
    }

    .card-show {
      width: 40%;
      display: flex;
      justify-content: center;
      align-items: center;

      @media (max-width: 578px) {
        width: 100%;
        padding: 0rem 0 2rem 0;
      }
    }

    .user-address {
      display: flex;
      gap: 0 1rem;

      @media (min-width: 1900px) {
        gap: 0 5rem;
      }

      @media (max-width: 575px) {
        flex-direction: column;
      }

      .street {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 1rem;
      }

      .location {
        width: 50%;
        display: flex;
        flex-direction: column;
        gap: 1rem;

        @media (max-width: 575px) {
          flex-direction: column;
          margin-top: 10px;
          width: 100%;
        }

        .number-plus {
          display: flex;
          gap: 0 1rem;
        }
      }
    }
  }

  .pix {
    animation: fadeIn 1s;
    display: flex;
    gap: 1rem;
    width: 100%;
    flex-direction: column;

    @media (max-width: 578px) {
      animation: fadeIn 0.1ms;
      flex-direction: column;
      gap: 1rem 0;
    }

    .installments {
      font-weight: bold;
      width: 30%;
      @media (max-width: 578px) {
        width: 100%;
      }
    }

    .pix-wrapper {
      display: flex;
      animation: fadeIn 1s;

      @media (max-width: 578px) {
        animation: fadeIn 0.1ms;
        flex-direction: column-reverse;
      }
    }

    .pix-code {
      width: 70%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1rem 0;

      @media (max-width: 578px) {
        width: 100%;
      }

      textarea {
        resize: none;
        height: 100px;

        @media (max-width: 578px) {
          height: 200px;
        }
      }
    }

    .qrcode {
      display: flex;
      justify-content: center;
      width: 30%;

      @media (max-width: 578px) {
        width: 100%;
        margin-bottom: 10px;
      }
    }
  }

  .boleto {
    animation: fadeIn 1s;
    display: flex;
    gap: 1rem;
    width: 100%;
    flex-direction: column;

    @media (max-width: 578px) {
      animation: fadeIn 0.1ms;
      flex-direction: column;
    }

    .boleto-wrapper {
      display: flex;
      gap: 0 3rem;
      animation: fadeIn 1s;
      @media (max-width: 578px) {
        animation: fadeIn 0.1ms;
        flex-direction: column;
      }
    }

    .installments {
      font-weight: bold;
      width: 30%;
      @media (max-width: 578px) {
        width: 100%;
      }
    }

    .boleto-code {
      width: 70%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1rem 0;

      @media (max-width: 578px) {
        width: 100%;
      }

      textarea {
        resize: none;
        height: fit-content;
      }
    }

    .actions {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 30%;
      gap: 1.1rem 0;

      @media (max-width: 578px) {
        width: 100%;
        margin-top: 20px;
      }

      button {
        width: 100%;
        padding: 1rem 0;

        transition: 0.2s;
        border-radius: 3px;
        color: $white;
        font-weight: bold;
        font-size: 1rem;

        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0 0.5rem;
        height: 59px;

        span {
          margin-top: 3px;
        }

        &.print {
          background: #3498db;
          border: 1px solid #3498db;

          &:hover {
            transition: 0.2s;
            background: #2980b9;
            border: 1px solid #2980b9;
          }
        }

        &.download {
          background: #1abc9c;
          border: 1px solid #1abc9c;
          &:hover {
            transition: 0.2s;
            background: #16a085;
            border: 1px solid #16a085;
          }
        }
      }
    }
  }
}
