.content-main {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem 0;

  .header-main {
    display: flex;
    justify-content: center;
  }

  .tutorials {
    display: flex;

    .tutorial-description {
      display: flex;
      flex-direction: column;
      gap: 1rem 0;

      @media (max-width: 575px) {
        margin-top: 2rem;
      }

      strong {
        font-size: 1.2rem;
      }
    }
  }
}
