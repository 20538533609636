.fallback {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.28335084033613445) 75%, rgba(142, 186, 54, 1) 100%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  gap: 30px 0;

  .brand {
    img {
      width: 200px;
    }
  }

  .message {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
