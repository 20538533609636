.new-menu {
  background: $white;
  height: 65px;
  display: flex;
  align-items: center;
  padding-right: 40px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

  .sidemenu {
    padding-left: 40px;

    .fake-bar {
      background: $white;
      position: absolute;
      min-height: 100%;
      width: 80px;
      left: 0;
      top: 0;
      z-index: 2;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 16px;
      gap: 40px 0;
      box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;

      transition: 0.1s;

      @media (max-width: 578px) {
        left: -100%;
        transition: 0.5s;
      }

      .side-logo {
        width: 35px;
        height: 35px;

        @media (max-width: 578px) {
          display: none;
        }
      }

      &.active {
        @media (max-width: 578px) {
          transition: 0.5s;
          width: 80vw;
          background: $white;
          left: 0;
        }
        transition: 0.1s;
        width: 20vw;

        .links {
          margin-left: 0;

          @media (min-width: 578px) {
            width: 100%;
          }

          .item {
            width: 100%;
            align-items: flex-start;
            span {
              display: flex;
            }
          }
        }
      }

      .links {
        display: flex;
        flex-direction: column;
        gap: 30px 0;

        .item {
          display: flex !important;
          align-items: center;
          gap: 0;
          width: 100%;
          padding: 10px;
          // border-radius: 7px;

          font-size: 20px;

          transition: 0.1s;

          flex-direction: column;

          .main {
            display: flex;
          }

          .sub {
            display: flex;
            flex-direction: column;
          }

          &:hover {
            color: $white;
            background: rgba(34, 95, 107, 0.8);
          }

          span {
            // display: none;
            @media (min-width: 578px) {
              display: none;
            }
            margin-top: 2px;
            font-size: 16px;
            font-weight: bold;
          }
        }
      }

      .header-mobile {
        @media (min-width: 578px) {
          display: none;
        }
        display: flex;
        align-items: center;

        img {
          width: 130px;
        }

        .close-icon {
          position: absolute;
          right: 20px;
        }
      }

      .copyright {
        @media (min-width: 578px) {
          display: none;
        }
        position: absolute;
        text-align: center;
        bottom: 40px;
      }
    }
  }

  .brand {
    display: flex;
    justify-content: flex-end;
    width: 100%;

    img {
      width: 125px;
    }

    @media (max-width: 578px) {
      justify-content: center;
    }
  }

  .menu-content {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    gap: 0 50px;

    @media (max-width: 578px) {
      display: none;
    }
  }

  .menu {
    display: flex;
    gap: 0 30px;

    .menu-item {
      display: flex;
      align-items: center;
      gap: 0 5px;

      &:hover {
        color: $secondary-color;
      }

      a {
        margin-top: 3px;
        font-size: 16px;
        letter-spacing: 0;
        &:hover {
          color: $secondary-color;
        }
      }
    }

    .notifications {
      @keyframes fadeIn {
        0% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }
      width: 100px;

      .icon {
        display: flex;
        justify-content: flex-end;
        position: relative;
        cursor: pointer;
      }

      .badge {
        background: crimson;
        display: flex;
        align-items: center;
        border-radius: 50%;
        position: absolute;
        right: -8px;
        top: -5px;
      }

      .bell {
        margin-top: 1px;
        &:hover {
          color: $secondary-color;
        }
      }
      .wrapper {
        animation: fadeIn 0.2s;
        background: $white;
        box-shadow: 0 0 37px rgba(8, 21, 66, 0.1);
        width: 300px;
        height: 300px;
        position: absolute;
        border: 1px solid $light-semi-gray;
        border-radius: 5px;
        right: 380px;
        z-index: 10;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .list {
        // background: pink;
        width: 95%;
        height: 95%;
        border: 1px solid $light-semi-gray;
        border-radius: 5px;

        display: flex;
        flex-direction: column;
        gap: 1rem 0;
        padding: 10px;
      }

      .title {
        font-weight: bold;
        font-size: 1.1rem;
      }

      .items {
        display: flex;
        flex-direction: column;
        gap: 0.5rem 0;
        align-items: flex-start;
        overflow: auto;

        .item {
          display: flex;
          flex-direction: column;
          border-bottom: 1px solid $light-semi-gray;
          padding: 0.5rem 0 0.5rem 0.5rem;
          width: 100%;
          transition: 0.1s;

          .description {
            display: flex;
            align-items: center;
            gap: 0 0.5rem;
            font-weight: bold;
          }

          span {
            font-size: 1.1rem;
          }

          small {
            font-size: 0.7rem;
          }

          &:hover {
            // background: $secondary-color;
            background: rgba(29, 245, 29, 0.2);
            border-radius: 5px;
            // color: $white;
            font-weight: bold;
            cursor: pointer;
          }
        }
      }
    }
  }

  .full-notification {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem 0;
    padding: 0.5rem;
    background: crimson;
  }

  .user-info {
    display: flex;
    align-items: center;
    gap: 0 10px;
    padding: 20px 0;

    &:hover {
      .actions {
        display: flex;
      }
    }

    img {
      width: 45px;
      height: 45px;
      border-radius: 50%;
    }

    .role {
      display: flex;
      flex-direction: column;
      text-align: center;
    }

    .actions {
      &:hover {
        display: flex;
      }
      background: $white;
      padding: 10px 25px;
      width: 150px;
      position: absolute;
      right: 32px;
      top: 60px;
      display: none;
      flex-direction: column;
      gap: 5px 0;
      align-items: center;
      z-index: 10;
      animation: fadeIn 0.1s;

      span {
        cursor: pointer;
      }

      @keyframes fadeIn {
        0% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }
    }
  }
}
