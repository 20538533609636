.table-actions {
  &.search {
    display: flex;
    align-items: center;
    width: 1000px;
    gap: 0 1rem;

    input {
      width: 70%;
    }

    span {
      transition: 0.1s;
      font-weight: bold;
      cursor: pointer;
    }
  }

  &.wrapper {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding: 0 0 10px 0;
    gap: 0 10px;

    @media (max-width: 575.98px) {
      justify-content: flex-end;
    }

    button {
      display: flex;
      gap: 0 2px;
    }
  }
}