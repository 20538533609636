/* eslint-disable */
.u-profile {
  .card {
    border-radius: 5px;
    padding: 25px;

    strong {
      color: $primary-color;
      font-size: 16px;
      padding-bottom: 15px;
    }

    .actions {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 15px;
      margin-top: 20px;
    }
  }
  .info {
    display: flex;
    flex-direction: column;
    gap: 20px 0;
    align-items: center;

    .photo {
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 130px;
        height: 130px;
        border: 1px solid #bdc3c7;
        border-radius: 50%;
        padding: 3px;
      }
    }

    .business {
      display: flex;
      align-items: center;
      gap: 0 10px;

      .plan {
        background: $secondary-color;
        // font-weight: bold;
        padding: 3px 15px;
        border-radius: 5px;
        text-align: center;
        color: $white;
      }

      .profile {
        background: $primary-color;
        // font-weight: bold;
        padding: 3px 15px;
        border-radius: 5px;
        text-align: center;
        color: $white;
      }
    }
  }

  .personal {
    .items {
      display: flex;
      flex-direction: column;
      gap: 15px 0;
    }
    .document {
      @media (max-width: 575px) {
        gap: 1rem 0;
      }
    }
    .beAccredited {
      @media (max-width: 575px) {
        gap: 1rem 0;
      }
    }
  }

  .fast-links {
    .items {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      justify-items: center;
      padding: 20px 0;
      gap: 2rem 0;
    }

    .item {
      a {
        display: flex;
        flex-direction: column;
        gap: 10px 0;
        align-items: center;
        width: fit-content;
        cursor: pointer;
        color: inherit;

        transition: 0.1s;

        .icon {
          color: white;
          width: 70px;
          padding: 15px;
          border-radius: 50%;
          height: 70px;
          display: flex;
          justify-content: center;
          align-items: center;

          &:hover {
            transition: 0.1s;
            box-shadow: rgba(0, 0, 0, 0.3) 0px 4px 12px;
          }
        }
      }
    }
  }

  .location {
    background: orange;
  }
}
