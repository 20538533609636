.accordion-button {
  font-family: $font-kiye;
  font-weight: bold;

  &:not(.collapsed) {
    // background: $light-gray;
    background: #ecf0f1;
    color: $dark-color;
    &::after {
      background-image: var(--bs-accordion-btn-icon);
    }
  }

  &:focus {
    border: none;
    box-shadow: none;
  }
}
